(function($) {
    /**  Form Submissions
    *********************/
  
    // Caldera Forms On Submit
    jQuery( document ).on( 'cf.form.submit', function ( event, data ) {
      function setFromPage() {
        // Current Page Title (first part)
        var title   = document.title;
            title   = title.split(" |", 1).pop();
        var origin  = window.location.origin;
  
        // Send GA Event
        return ga('send', 'event', 'Form', 'submit', origin);
      }
      setFromPage();
    });

    jQuery(document).on('gform_confirmation_loaded', function(event, formId){
      function setFormEvent() {
        // Current Page Title (first part)
        var title   = document.title;
            title   = title.split(" |", 1).pop();
        var origin  = window.location.origin;
  
        // Send GA Event
        return ga('send', 'event', 'Form', 'submit', origin);
      }
      setFormEvent();
    });

    
    /**
     *  Locations
     ********************/
    var loc = $(".ga-location-ph");
    var place;

    loc.click(function() {
      /*
      * All Locations are dynamic generated using the WP Loop
      * this solution future proofs the tracking by adding the value attribute to the href
      * 
      * So if new locations are added no editing of this file is necessary
      * 
      */
      place = $(this).attr('value'); 
      console.log(place);
      ga('send', 'event', 'Phone Number', 'click', place );
    });


    /**
     *  Location Online Scheduler
     ********************/
    var los = $(".loc-online");
    var location;
    
    los.click(function() {
      location = $(this).attr('data-loc'); 
      console.log('Clicked - ' + location);
      ga('send', 'event', 'Scheduler', 'click', location );
    });

    var losp = $(".loc-online-page");
    var locationId;
    
    losp.click(function() {
      locationId = $(this).attr('data-loc'); 
      console.log('Clicked - ' + locationId);
      ga('send', 'event', 'Scheduler', 'click', locationId );
    });


    
    /**  Button w/ ID
    *********************/
    var buttonTollFree = $("#callTollFree");
    buttonTollFree.click(function() {
      ga('send', 'event', 'Phone Number', 'click', 'Toll Free' );
    });

    var button2TollFree = $(".tollFree").find('a');
    button2TollFree.click(function() {
      ga('send', 'event', 'Phone Number', 'click', 'Toll Free' );
    });

    var buttonScheduleBH = $("#btn__schedule--bh").find('a');
    buttonScheduleBH.click(function() {
      ga('send', 'event', 'Schedule', 'click', 'Bloomfield Hills' );
    });

    var buttonScheduleSF = $("#btn__schedule--sf").find('a');
    buttonScheduleSF.click(function() {
      ga('send', 'event', 'Schedule', 'click', 'Southfield' );
    });

    var buttonScheduleWAR = $("#btn__schedule--war").find('a');
    buttonScheduleWAR.click(function() {
      ga('send', 'event', 'Schedule', 'click', 'Warren' );
    });

    var buttonScheduleSH = $("#btn__schedule--sh").find('a');
    buttonScheduleSH.click(function() {
      ga('send', 'event', 'Schedule', 'click', 'Shelby' );
    });

    var buttonScheduleFM = $("#btn__schedule--fm").find('a');
    buttonScheduleFM.click(function() {
      ga('send', 'event', 'Schedule', 'click', 'Farmington' );
    });
    

    // frmtrack.click(function() {
    //   //console.log('Contact Form - ' + origin);
    //   ga('send', 'event', 'Form', 'click', 'Contact Form - ' + origin );
    // });

    // frmPosting.click(function() {
    //   //console.log('Contact Form - ' + origin);
    //   ga('send', 'event', 'Form', 'click', 'Job Posting - ' + origin );
    // });


  })(jQuery);