(function($) {
    $(document).ready(function() {
        $(window).scroll(function(){
            if ($(window).scrollTop() >= 30) {
                $('header').addClass('shadow-header');
                $(".hotbtn").addClass("closed");
            } else {
                $('header').removeClass('shadow-header');
                $(".hotbtn").removeClass("closed");
            }
        });
    });
})(jQuery);